(function (global) {
	"use strict";

	function GoogleExperiment() {

		if (GoogleExperiment.prototype._singletonInstance){
			return GoogleExperiment.prototype._singletonInstance;
		}

		GoogleExperiment.prototype._singletonInstance = this;

		var queue = [], experiments = {};

		function sendExperiment (tracker, experimentVar, experimentId) {
			if(cxApi){
				cxApi.setChosenVariation(experimentVar, experimentId);
				tracker.send('event', 'experiment', 'view', {'nonInteraction': 1});
			}
		}


		function sendAll() {
			$.when.apply(null, queue).done(function() {
				if( !$.isEmptyObject(experiments) ){
					ga(function(tracker) {
						for(var i in experiments){
							if(experiments.hasOwnProperty(i)){
								sendExperiment(tracker, experiments[ i ], i);
							}
						}
					});
				}
			});
		}


		function setVariant(experimentId, callback){

			queue.push( $.getScript('//www.google-analytics.com/cx/api.js?experiment=' + experimentId,

				function(){
					var variant;

					if('undefined' !== typeof cxApi){
						variant = cxApi.chooseVariation();
						experiments[ experimentId ] = variant;
						if('function' === typeof callback){
							callback( experiments[ experimentId ] );
						}
					}
				}
			));
		}

		return {
			setVariant: setVariant,
			sendAll: sendAll
		}
	}

	global.GoogleExperiment = new GoogleExperiment();
    var test = 'test';

} (window));