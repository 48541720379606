app.controller('FormController',['$scope', '$http', function($scope, $http) {
    $scope.submitForm = function(form, formName, url) {
        $http.post(url, form).
            success(function(data, status, headers, config) {
                //if (data.errors)
                //console.log(formName);
                $scope[formName].alertSuccess = true;
            }).
            error(function(data, status, headers, config) {
                $scope[formName].alertError = true;
            });
    };
}]);