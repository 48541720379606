$(document).ready(function(){
    $("#nav li.sub-menu").hover(
        function(){
            var ws = $( window ).width();
            if(ws > 975){
                var pos = $( this ).position().top;
                var w = $(this).parent().width();
                $(this).children('ul').hide();
                $(this).children('ul')
                    .css('visibility', 'visible')
                    .css('display', 'block')
                    .css('top', pos)
                    .css('left', w-5);
            }
        },
        function () {
            $('ul', this).css('visibility', 'hidden').css('display', 'none');
        });
});