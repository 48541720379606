$(document).ready(function() {
    "use strict";

	var popoverTimout;

    $('[data-toggle="tooltip"]').tooltip();

    // popovers, всплывающие подсказки: http://getbootstrap.com/javascript/#popovers
    $("[data-toggle=popover]").popover({/*trigger: "hover", */placement: 'bottom', animation: true, html: true, container: "#temp"

		, trigger: "manual"
	})
		.on("mouseenter click", function () {

			var _this = this;

			$(this).popover("show");

			$(".popover").on("mouseleave", function(){

				$(_this).popover('hide');
			});
		})
		.on("mouseleave", function(){

			var _this = this;

			popoverTimout = setTimeout(function(){

				if (!$(".popover:hover").length) {

					$(_this).popover("hide");
				}
			}, 150);
	});

    // Сообщения в модальных окнах Flash::overlay('...')
    $('#flash-overlay-modal').modal();

    $('.datepicker').datepicker({
        language: "ru",
        format: 'dd.mm.yyyy',
        startDate: new Date(),
        autoclose: true
    });


	$('.store_availability_control').on('click', function(){

		var post = {csrf_token: $('meta[name="csrf_token"]').attr('content'), 'X-HTTP-Method-Override': 'POST'};

		post[ 'detailed_store_availability' ]  = $(this).attr('a');

		$.post('/tyres/toggle_store_alailability', post,
			function(){
				window.location.reload();
			}
		);

		return false;
	});






});