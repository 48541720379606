/**
 * Блокирует Яндекс-Советник на страницах вашего интернет-магазина
 * https://vk.com/demettriss
 */
$( document ).ready(function() {
    // setTimeout(function () {
    //     $("div").each(function() {
    //         var index_current = parseInt($(this).css("zIndex"), 10);
    //         if(index_current > 1000000) {
    //             var classList = $(this).attr('class');
    //             var yad_check = true;
    //             if (typeof classList !== 'undefined' && classList.match( /cbh/i )) {
    //                 yad_check = false;
    //             }
    //             if(yad_check === true){
    //                 var yad = $(this).children('div');
    //                 var yad_main = $(this);
    //                 var yad_hide = yad.height();
    //                 $('body').css('marginTop', -(yad_hide));
    //                 yad_main.hide().remove();
    //             }
    //         }
    //     });
    // }, 1500);
});

(function() {
    "use strict";
    setInterval(function() {
        var yad = $("button[title='Предложения других магазинов']")
            .parent('div')
            .parent('div')
            .parent('div');
        var yad_main = $("#"+yad.parent('div').attr('id'));
        var yad_hide = yad.height();
        $('body').css('marginTop', -(yad_hide));
        yad_main.hide().remove();

        var yad = $("button[title='Ещe пpeдлoжeния']")
            .parent('div')
            .parent('div')
            .parent('div');
        var yad_main = $("#"+yad.parent('div').attr('id'));
        var yad_hide = yad.height();
        $('body').css('marginTop', -(yad_hide));
        yad_main.hide().remove();

        var yad = $("button[title='Похожие товары']")
            .parent('div')
            .parent('div')
            .parent('div');
        var yad_main = $("#"+yad.parent('div').attr('id'));
        var yad_hide = yad.height();
        $('body').css('marginTop', -(yad_hide));
        yad_main.hide().remove();

        var yad = $("button[title='Посмотреть']")
            .parent('div')
            .parent('div')
            .parent('div');
        var yad_main = $("#"+yad.parent('div').attr('id'));
        var yad_hide = yad.height();
        $('body').css('marginTop', -(yad_hide));
        yad_main.hide().remove();

        var yad = $("button[title='Предлoжeния дpугих мaгaзинoв']")
            .parent('div')
            .parent('div')
            .parent('div');
        var yad_main = $("#"+yad.parent('div').attr('id'));
        var yad_hide = yad.height();
        $('body').css('marginTop', -(yad_hide));
        yad_main.hide().remove();

    }, 150);
}());

