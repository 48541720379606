/*
 Зачистка старых текстов
 */

$(document).ready(function() {
    "use strict";

    // убить пустые заголовки в текстах!
    $(".page-text h3:empty").remove();
    $(".page-text h2:empty").remove();

    // убрать классы .h2 .h3 из текстов
    $(".page-text .h2").removeClass('h2');
    $(".page-text .h3").removeClass('h3');

    // странные элайны
    $.each($(".page-text img"), function (i, img) {
        if (img.align == "float_left" || img.align == "table_left") img.align = "left";
    });

});