app.controller('liveSearchController', ['$scope', function($scope) {

    // docs https://github.com/ghiden/angucomplete-alt

    $scope.selectedObjectAction = function(obj) {
        if (obj != undefined) {
            //console.log(obj);
            $scope.searchTerm = obj;
            window.location = "/" + obj.originalObject.uri;
        }
    };
    $scope.onInputChange = function(obj) {
        //console.log(obj);
        $scope.searchTerm = obj;
    };
    $scope.submit = function() {
        //console.log($scope.searchTerm);
        //alert($scope.searchTerm);
        window.location = "/search?q=" + $scope.searchTerm;
    };
}]);


$(document).ready(function() {
    "use strict";

    $('.angucomplete-dropdown').prepend('<h3>Поисковые подсказки</h3><p class="alert alert-success">Выберите одну из подсказок для перехода на эту страницу или нажмите [Enter] для обычного поиска.</p>');

});