$(document).ready(function() {
    "use strict";

    // docs: http://fancyapps.com/fancybox/
    $(".fancybox").fancybox({
        prevEffect: 'fade',
        nextEffect: 'fade',
        nextSpeed: 1000,
        prevSpeed: 500,
        helpers: {
            title: {
                type: 'outside'
            },
            thumbs: {
                width: 50,
                height: 50
            },
            buttons: {}
        }
    });

    $(".various").fancybox({
        maxWidth: 800,
        maxHeight: 600,
        fitToView: false,
        width: '70%',
        height: '70%',
        autoSize: false,
        closeClick: false,
        openEffect: 'none',
        closeEffect: 'none'
    });
});