/*
    Модальное окно в хедере с картой и адресами
 */
$(document).ready(function() {
    "use strict";

    var mapLoaded = false;

    // привязываем модальное окно с картой и адресами к кнопке
    $(".showMapBox").on("click", function () {

		ga('send', 'event', {eventCategory: 'Contacts', eventAction: 'Click', eventLabel: 'Вызов модального окна с картой'});
		yaCounter93394.reachGoal('Goal_Contacts');

        $('#modalMapBox').modal('toggle');
    });

    // при показе модального окна надо в него яндекс-карту загрузить
    $('#modalMapBox')
        .on('shown.bs.modal', function () {
            // после загрузки выполнится функция initMap из глобального пространства - см ее нижее
            // $.getScript('//api-maps.yandex.ru/2.1/?lang=ru_RU&onload=initMap');

			if(!mapLoaded){

				$.getScript('https://api-maps.yandex.ru/services/constructor/1.0/js/?sid=34nRC9CtEyWmp9MlYqewomTdaGiKMMjt&width=100%25&height=100%&lang=ru_RU&sourceType=mymaps&scroll=true&id=modalMapBoxPlacment',
					function () {

						mapLoaded = true;
					}
				)
			}

        })/**/
        .on('hidden.bs.modal', function (myMap) {
            //$('#map').html(''); // уничтожить карту после скрытия модального окна
        });
});

// функция должна быть в глобальном пространстве имен ибо вызывается из скрипты яндекса.
function initMap(ymaps) {
    $('#map').html('');

    var myMap = new ymaps.Map('map', {
        center: [55.76, 37.64],
        zoom: 10
    });

    // API: https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geoXml.load-docpage/
    ymaps.geoXml
        .load("http://maps.yandex.ru/export/usermaps/34nRC9CtEyWmp9MlYqewomTdaGiKMMjt/")
        .then(function(res){
            // добавление элементов ymapsml-файла на карте
            myMap.geoObjects.add(res.geoObjects);
        })
}
