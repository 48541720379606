/*
    Плавающая корзина
 */
$(document).ready(function(){
    "use strict";


	return;

    function cart_redraw(){
        var cart = $('.cart-mini');
        if(cart.html()){ // если что-то есть в корзине...
            //var cartWidth = 100;
            var padTop = 20;

            $("#debug").attr("value", $(document).width());

            // относительно чего считать будем?
            var header = $('.page_header');
            var c = $("#wrapper");

            var  dyrkaLeft = c.offset().left; // расстояние справа от края страницы до границы окна - дырка под корзину

            //if(dyrkaLeft > cartWidth){ // если дырка больше чем корзинка
            if ($(document).width()>=1380) {
                $('.cart-mini').css('margin-left', (dyrkaLeft + c.outerWidth()) +'px'); // позиционируем корзинку точно в дырочку
                if(!cart.hasClass('short'))
                    cart.addClass('short'); // присваиваем класс узкой корзинки
                header.css('padding-top', padTop+ 'px'); // не понял!
                if(cart.hasClass('float-top'))
                    cart.removeClass('float-top'); // убираем возможно присвоенный ранее класс
            }else{
                if(cart.hasClass('short'))
                    cart.removeClass('short'); // убираем возможно присвоенный ранее класс
                cart.css('margin-left', 'auto'); // убираем корзинку из дырочки
                header.css('padding-top', (cart.height() + padTop) +'px'); // снижаем хедер под корзинку
                if(cart.offset().top > 1){ // если скроллим страницу вниз...
                    if(!cart.hasClass('float-top'))
                        cart.addClass('float-top'); // тогда включаем плавающую полупрозрачную корзинку
                }else
                    cart.removeClass('float-top'); // если не скролим - то убираем лишний класс
            }
        }
    }

    /* cart */
    cart_redraw();// инициализация положения корзины

    // перерисовать корзину в случае ресайза окна
    $(window).resize(function(){
        cart_redraw();
    });

});